


































































































































































































































































import AppearanceEditor from "@/components/AppearanceEditor.vue";
import AppearancePushNotification from "@/components/AppearancePushNotification.vue";
import DeleteDialog from "@/components/DeleteDialog.vue";
import {
  IAppearanceCreate,
  IAppearanceGet,
  IAppearanceTreeNodeCreate,
} from "@/interfaces";
import {
  dispatchCreateAppearances,
  dispatchDeleteAppearances,
  dispatchGetAppearances,
  dispatchGetTreeNodes,
  dispatchSaveTreeNode,
  dispatchSendPushNotification,
  dispatchUpdateAppearances,
} from "@/store/admin/actions";
import {
  readCityId,
  readHasCityAdminAccess,
  readParsedToken,
} from "@/store/main/getters";
import { Component, Vue } from "vue-property-decorator";
import draggable from "vuedraggable";

@Component({
  components: {
    AppearanceEditor,
    DeleteDialog,
    AppearancePushNotification,
    draggable,
  },
})
export default class CityAppearances extends Vue {
  private treeNodes: IAppearanceTreeNodeCreate[] = [];
  private loaded = false;

  private dialog = false;
  private editing = false;
  private addingSubNode = false;
  private nodeId = -1;
  private subNodeId = -1;
  private dialogInput = "";

  private appearances: Map<number, IAppearanceGet[]> = new Map<
    number,
    IAppearanceGet[]
  >();
  private showAppearanceEditor = false;
  private editingAppearanceId: number = -1;
  private editingAppearance: IAppearanceCreate = {
    order_id: 0,
    title: "",
    content: "[]",
    files: [],
    appearance_tree_node_id: -1,
  };
  private editingAppearanceFiles: { name: string; file: File }[] = [];

  private showDeleteDialog = false;
  private deleteDialogTitle = "";
  private deleteDialogBody = "";

  private deleteCallback: Function | null = null;
  private showAppearancePushNotification = false;
  private pushNotificationAppearanceId = -1;

  public async mounted() {
    this.treeNodes = await dispatchGetTreeNodes(
      this.$store,
      readCityId(this.$store)
    );
    this.sortTreeNodes();
    this.loaded = true;

    if (this.hasCityAdminAccess) return;

    this.treeNodes.forEach(
      (treeNode) =>
        (treeNode.sub_nodes = treeNode.sub_nodes.filter((subNode) =>
          this.canEditPostsInSubNode(subNode.id!)
        ))
    );
    this.treeNodes = this.treeNodes.filter(
      (treeNode) => treeNode.sub_nodes.length > 0
    );
  }

  private sortTreeNodes() {
    this.treeNodes.sort((a, b) => a.order_id - b.order_id);
    this.treeNodes.forEach((node) =>
      node.sub_nodes.sort((a, b) => a.order_id - b.order_id)
    );
  }

  private editNode(nodeId: number) {
    this.dialog = true;
    this.editing = true;
    this.addingSubNode = false;
    this.nodeId = nodeId;
    this.dialogInput = this.treeNodes[nodeId].title;
  }

  private editSubNode(nodeId: number, subNodeId: number) {
    this.dialog = true;
    this.editing = true;
    this.addingSubNode = true;
    this.nodeId = nodeId;
    this.subNodeId = subNodeId;
    this.dialogInput = this.treeNodes[nodeId].sub_nodes[subNodeId].title;
  }

  private addNode() {
    this.dialog = true;
    this.editing = false;
    this.addingSubNode = false;
    this.dialogInput = "";
  }

  private addSubNode(nodeId: number) {
    this.dialog = true;
    this.editing = false;
    this.addingSubNode = true;
    this.nodeId = nodeId;
    this.dialogInput = "";
  }

  private abortAdding() {
    this.dialog = false;
  }

  private async commitAdding() {
    if (this.editing) {
      if (this.addingSubNode) {
        this.treeNodes[this.nodeId].sub_nodes[this.subNodeId].title =
          this.dialogInput;
      } else {
        this.treeNodes[this.nodeId].title = this.dialogInput;
      }
    } else {
      if (this.addingSubNode) {
        this.treeNodes[this.nodeId].sub_nodes.push({
          title: this.dialogInput,
          order_id: this.treeNodes[this.nodeId].sub_nodes.length,
          appearance_order_nodes: [],
        });
      } else {
        this.treeNodes.push({
          title: this.dialogInput,
          order_id: this.treeNodes.length,
          sub_nodes: [],
        });
      }
    }
    this.dialog = false;
  }

  public get hasCityAdminAccess() {
    return readHasCityAdminAccess(this.$store);
  }

  public canEditPostsInSubNode(subNodeId: number) {
    const token = readParsedToken(this.$store);
    return (
      token?.is_superuser ||
      token?.is_city_admin ||
      token?.appearances_write_access.includes(subNodeId)
    );
  }

  public async loadAppearances(subNodeId: number) {
    if (
      !this.canEditPostsInSubNode(subNodeId) ||
      this.appearances.has(subNodeId)
    )
      return;

    let appearances = await dispatchGetAppearances(this.$store, {
      cityId: readCityId(this.$store),
      subNodeId: subNodeId,
    });
    appearances.sort((a, b) => a.order_id - b.order_id);
    this.appearances = new Map(this.appearances.set(subNodeId, appearances));
  }

  private addAppearance(subNodeId: number) {
    this.editingAppearance = {
      order_id: this.appearances.get(subNodeId)?.length ?? 0,
      title: "",
      content: "[]",
      files: [],
      appearance_tree_node_id: subNodeId,
    };
    this.editingAppearanceFiles = [];
    this.editingAppearanceId = -1;

    this.showAppearanceEditor = true;
  }

  public editAppearance(subNodeId: number, index: number) {
    this.editingAppearance = this.appearances.get(subNodeId)![index];
    this.editingAppearanceId = this.appearances.get(subNodeId)![index].id;
    this.editingAppearanceFiles = [];

    this.showAppearanceEditor = true;
  }

  private removeNode(nodeId: number) {
    var treeNode = this.treeNodes[nodeId];

    this.showDeleteDialog = true;
    this.deleteDialogTitle = "Kategorie löschen";
    this.deleteDialogBody =
      "Möchten Sie die Kategorie " +
      treeNode.id +
      ' - "' +
      treeNode.title +
      '" wirklich löschen?';
    this.deleteCallback = async () => {
      this.$delete(this.treeNodes, nodeId);

      this.abortDelete();
    };
  }

  private removeSubNode(nodeId: number, subNodeId: number) {
    var subNode = this.treeNodes[nodeId].sub_nodes[subNodeId];

    this.showDeleteDialog = true;
    this.deleteDialogTitle = "Sub-Kategorie löschen";
    this.deleteDialogBody =
      "Möchten Sie die Sub-Kategorie " +
      subNode.id +
      ' - "' +
      subNode.title +
      '" wirklich löschen?';
    this.deleteCallback = async () => {
      this.$delete(this.treeNodes[nodeId].sub_nodes, subNodeId);

      this.abortDelete();
    };
  }

  private removeAppearance(subNodeId: number, index: number) {
    var deleteAppearance = this.appearances.get(subNodeId)![index];
    this.showDeleteDialog = true;
    this.deleteDialogTitle = "Auftritt löschen";
    this.deleteDialogBody =
      "Möchten Sie den Auftritt " +
      deleteAppearance.id +
      ' - "' +
      deleteAppearance.title +
      '" wirklich löschen?';
    this.deleteCallback = async () => {
      await dispatchDeleteAppearances(this.$store, {
        cityId: readCityId(this.$store),
        appearanceId: deleteAppearance!.id,
      });

      let subNodeId = deleteAppearance!.appearance_tree_node_id;
      var appearances = this.appearances.get(subNodeId)!;
      appearances = appearances.filter(
        (value) => value.id != deleteAppearance!.id
      );
      this.appearances.set(subNodeId, appearances);
      this.appearances = new Map(this.appearances);

      this.abortDelete();
    };
  }

  private abortDelete() {
    this.deleteCallback = null;
    this.deleteDialogTitle = "";
    this.deleteDialogBody = "";
    this.showDeleteDialog = false;
  }

  private async commitDelete() {
    await this.deleteCallback?.call(this);
  }

  public abortEditingAppearance() {
    this.showAppearanceEditor = false;
  }

  public async saveEditingAppearance() {
    let subNodeId = this.editingAppearance.appearance_tree_node_id;
    this.appearances.delete(subNodeId);
    this.appearances = new Map(this.appearances);

    if (this.editingAppearanceId == -1) {
      await dispatchCreateAppearances(this.$store, {
        cityId: readCityId(this.$store),
        appearance: this.editingAppearance!,
        files: this.editingAppearanceFiles,
      });
    } else {
      await dispatchUpdateAppearances(this.$store, {
        cityId: readCityId(this.$store),
        appearanceId: this.editingAppearanceId!,
        appearance: this.editingAppearance!,
        files: this.editingAppearanceFiles,
      });
    }

    this.showAppearanceEditor = false;
    let appearances = await dispatchGetAppearances(this.$store, {
      cityId: readCityId(this.$store),
      subNodeId: subNodeId,
    });
    this.appearances = new Map(this.appearances.set(subNodeId, appearances));
  }

  private pushNotification(subNodeId: number, index: number) {
    this.showAppearancePushNotification = true;
    this.pushNotificationAppearanceId =
      this.appearances.get(subNodeId)![index].id;
  }

  private abortPushNotification() {
    this.showAppearancePushNotification = false;
  }

  private async sendPushNotification(isSystemNotification: boolean) {
    await dispatchSendPushNotification(this.$store, {
      cityId: readCityId(this.$store),
      appearanceId: this.pushNotificationAppearanceId,
      isSystemNotification: isSystemNotification,
    });
    this.showAppearancePushNotification = false;
  }

  public async save() {
    for (const node of this.treeNodes) {
      for (const subNode of node.sub_nodes) {
        if (subNode.id) {
          for (const appearance of this.appearances.get(subNode.id) ?? []) {
            if (subNode.appearance_order_nodes == undefined)
              subNode.appearance_order_nodes = [];

            subNode.appearance_order_nodes.push({
              id: appearance.id,
              order_id: appearance.order_id,
            });
          }
        }
      }
    }

    this.treeNodes = await dispatchSaveTreeNode(this.$store, {
      cityId: readCityId(this.$store),
      nodes: this.treeNodes,
    });
    this.sortTreeNodes();
  }

  private dragged() {
    var nodeOrderId = 0;
    for (const node of this.treeNodes) {
      node.order_id = nodeOrderId;
      nodeOrderId++;

      var subNodeOrderId = 0;
      for (const subNode of node.sub_nodes) {
        subNode.order_id = subNodeOrderId;
        subNodeOrderId++;

        var appearanceOrderId = 0;
        if (subNode.id)
          for (const appearance of this.appearances.get(subNode.id) ?? []) {
            appearance.order_id = appearanceOrderId;
            appearanceOrderId++;
          }
      }
    }

    this.appearances = new Map(this.appearances);
  }
}
