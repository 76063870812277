






































import { readHasCityAdminAccess } from "@/store/main/getters";
import { Vue, Component, Emit, Prop } from "vue-property-decorator";

@Component
export default class AppearancePushNotification extends Vue {
  @Prop()
  private show: boolean = false;
  @Prop()
  private appearanceId: number = -1;
  private systemPushNotification = false;

  public async mounted() {}

  @Emit("abort")
  private abort() {}

  @Emit("send")
  private send() {}

  public get hasCityAdminAccess() {
    return readHasCityAdminAccess(this.$store);
  }
}
