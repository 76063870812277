




























import { Vue, Component, Emit, Prop } from "vue-property-decorator";

const AppearanceEditorProps = Vue.extend({
  props: {
    show: Boolean,
  },
});

@Component
export default class DeleteDialog extends AppearanceEditorProps {
  @Prop()
  private title!: string;

  @Prop()
  private body!: string;

  @Emit("abort")
  private abort() {}

  @Emit("commit")
  private commit() {}
}
