















































































































import { apiUrl } from "@/env";
import { IAppearanceCreate } from "@/interfaces";
import { isValidHttpUrl, isValidMailAdress } from "@/utils";
import { Vue, Component, Emit, Watch } from "vue-property-decorator";

const AppearanceEditorProps = Vue.extend({
  props: {
    appearance: Object,
    files: { type: Array },
    appearanceId: Number,
  },
});

@Component
export default class AppearanceEditor extends AppearanceEditorProps {
  private rowTypes: { id: string; title: string }[] = [
    { id: "text", title: "Text" },
    { id: "link", title: "Link" },
    { id: "image", title: "Bild" },
    { id: "file", title: "Datei" },
    { id: "mail", title: "E-Mail" },
    { id: "phone", title: "Telefonnummer" },
  ];
  private rules = {
    required: (value) => !!value || "Pflichtfeld",
    isUrl: (value) => isValidHttpUrl(value) || "Valide URL eingeben",
    isMail: (value) => isValidMailAdress(value) || "Valide E-Mail eingeben",
  };
  private rows: any[] = [];

  private get canSave() {
    for (const [i, row] of this.rows.entries()) {
      if (row.type == "link") {
        if (!isValidHttpUrl(row.content)) return false;
      }
      if (row.type == "image" || row.type == "file") {
        if (this.rows[i].content == "") return false;
      }
      if (row.type == "mail") {
        if (!isValidMailAdress(row.content)) return false;
      }
      if (row.type == "phone") {
        if (row.content == "") return false;
      }
    }
    return true;
  }

  public async mounted() {
    this.appearanceChanged(this.$props.appearance);
  }

  @Watch("appearance")
  private appearanceChanged(newValue: IAppearanceCreate) {
    this.rows = JSON.parse(newValue.content);
  }

  @Emit("abort")
  private abort() {
    this.rows = JSON.parse(this.appearance.content);
  }

  @Emit("save")
  private save() {
    this.appearance.content = JSON.stringify(this.rows);
  }

  private changedType(rowIdx: number) {
    for (let i = 0; i < this.files.length; i++) {
      const file: any = this.files[i];
      if (file.name == `${rowIdx}`) {
        this.$delete(this.files, i);
        break;
      }
    }

    this.rows[rowIdx].content = "";
  }

  private addRow() {
    this.rows.push({
      type: "text",
      content: "",
    });
  }

  private deleteRow(rowIndex: number) {
    this.$delete(this.rows, rowIndex);

    const fileName = `${rowIndex}`;
    for (let i = 0; i < this.files.length; i++) {
      const file: any = this.files[i];
      if (file.name == fileName) {
        this.$delete(this.files, i);
        break;
      }
    }
  }

  private getImageError(rowIdx: number) {
    if (this.rows[rowIdx].content == "") {
      return "Bitte wählen Sie eine Datei aus.";
    }
    return "";
  }

  private selectedFile(rowIdx: number, file: File) {
    const fileName = `${rowIdx}`;

    if (file == null) {
      for (let i = 0; i < this.files.length; i++) {
        const file: any = this.files[i];
        if (file.name == fileName) {
          this.$delete(this.files, i);
          break;
        }
      }
      this.rows[rowIdx].content = "";
    } else {
      this.rows[rowIdx].content = fileName;
      this.appearance.files.push(fileName);
      this.files.push({ name: fileName, file: file });
    }
  }

  private getRowFile(rowIdx: number) {
    if (this.rows[rowIdx].type != "image" || this.rows[rowIdx].content == "")
      return "";

    const fileName = `${rowIdx}`;
    for (let i = 0; i < this.files.length; i++) {
      const file: any = this.files[i];
      if (file.name == fileName) {
        return URL.createObjectURL(file.file);
      }
    }

    return apiUrl + "/api/v1" + this.rows[rowIdx].content;
  }
}
